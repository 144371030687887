import React, { useEffect, useRef, useState } from 'react'
import { convertToRaw, EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin, {
    Separator,
} from '@draft-js-plugins/static-toolbar';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    OrderedListButton,
    UnorderedListButton
} from '@draft-js-plugins/buttons';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import draftToHtml from 'draftjs-to-html';

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];

function DraftEditor({ setComment, setPlainComment = '' }) {
    const editor = useRef(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        editor?.current?.focus()
    }, [])

    const onChange = async editorState => {
        await setEditorState(editorState)

        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        // convert bloks into html
        if (rawContentState?.blocks?.length > 0 && rawContentState?.blocks[0]?.text !== '') {
            const htmlString = draftToHtml(rawContentState);
            setComment(htmlString)
            setPlainComment(editorState?.getCurrentContent()?.getPlainText('\u0001'))
        } else {
            setComment("")
            setPlainComment("")
        }
    };

    return (
        <div className='draft-editor-container'>
            <Toolbar>
                {(externalProps) => (
                    <div>
                        <BoldButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <ItalicButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <UnderlineButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <Separator />
                        <HeadlineOneButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <HeadlineTwoButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <HeadlineThreeButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <Separator />
                        <OrderedListButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                        <UnorderedListButton {...externalProps} getEditorState={externalProps.getEditorState} setEditorState={externalProps.setEditorState} />
                    </div>
                )}
            </Toolbar>
            <div>
                <Editor
                    ref={editor}
                    editorState={editorState}
                    onChange={onChange}
                    plugins={plugins}
                />
            </div>

        </div>
    )
}

export default DraftEditor