import { useRef, useLayoutEffect, useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { notification } from 'antd';

export const CUSTOMER_ROLE_TYPE = "ROLE_CUSTOMER";

export const DRAG_GENERAL_BACKGROUND = "#EAEAEA";
export const DRAG_DROP_BACKGROUND = "#eae5e0";

export const hextorgba = (color) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    var r = result ? parseInt(result[1], 16) : null;
    var g = result ? parseInt(result[2], 16) : null;
    var b = result ? parseInt(result[3], 16) : null;
    var a = '0.3';

    return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
}

export const lightOrDark = (color) => {
    var r, g, b, hsp;

    if (color.match(/^rgb/)) {

        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
        r = color[1];
        g = color[2];
        b = color[3];
    } 
    else {
        
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    if (hsp>127.5) {

        return 'light';
    } 
    else {

        return 'dark';
    }
}

export function useDimensions() {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const targetRef = useRef();
  const RESET_TIMEOUT = 100;

  const div_dimensions = () => {
    setHeight(targetRef.current.offsetHeight);
    setWidth(targetRef.current.offsetWidth);
  };

  useLayoutEffect(() => {
    div_dimensions();
  }, []);

  const debouncedDimensions = debounce(div_dimensions, RESET_TIMEOUT);

  useEffect(() => {
    window.addEventListener("resize", debouncedDimensions);
    return () => {
      window.removeEventListener("resize", debouncedDimensions);
    };
  });

  return [{ height, width }, targetRef];
}

// modal for konami

function toggleModal() {
  var modal = document.querySelector(".racer-modal");
  modal.classList.toggle("racer-show-modal");
  document.getElementById("racer-iframe").focus()
  document.getElementById("racer-iframe").click()
}

export function closeKonamiModal(iframe) {
  let modal = document.querySelector(".racer-modal");
  iframe.setAttribute('src', '');
  modal.classList.remove("racer-show-modal");
}

// Konami key event 
var pattern = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
var current = 0;
var widthForRacer = 0;
var heightForRacer = 0;

// Detecting window size
function detectWindowSize() {
  widthForRacer = document.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  heightForRacer = document.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

window.onresize = detectWindowSize;

var active = 0;

var keyHandler = function (event) {
  detectWindowSize()
  var iframe = document.getElementById("racer-iframe");
  if (widthForRacer < 1280) {
    closeKonamiModal(iframe)
    return;
  }

  if (event.key == 'Escape') {
    closeKonamiModal(iframe)
  }

  if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
    current = 0;
    return;
  }
  current++;

  if (pattern.length === current) {
    current = 0;
    active = active ? 0 : 1;
    console.log(active, 'active')
    if (active) {
      iframe.setAttribute('src', 'racer/racer.html');
    } else {
      iframe.setAttribute('src', '');
    }

    toggleModal();
  } 
};

export function konamiKeyEvent() {
  document.addEventListener('keydown', keyHandler);
}

export function removeKonamiKeyEvent() {
  document.removeEventListener('keydown', keyHandler);
}


// Notification
export const openNotification = (message, description) => {
  notification.open({
      message: message,
      description: description,
      onClick: () => {
      },
  });
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const grayColor = "#808080"

export const shortNameBackground = (date) => {
  var datum = ((Date.parse(date))/1000).toString();
  return '#' + datum.substring(datum.length - 6)
}

export const displayShortName = (user) => {
  if (user) {
      return (user.firstname == null ? '' : user.firstname.charAt(0)) + (user.lastname == null ? '' : user.lastname.charAt(0));
  }

  return '';
}

export const MAX_WEEKS = 56;